<template>
  <v-autocomplete
    v-model="field"
    :items="items"
    color="blue-grey lighten-2"
    :label="$t('point.selectTanakhBooks')"
    item-text="name"
    item-value="name"
    hide-details
    @update:search-input="handleChange"
  />
</template>
<script>
import { tanakhBooks } from "@/data/tanakhChaptersList"
export default {
  name: "tanakh-autocomplete",
  props: {
    value: String,
  },
  data() {
    return {
      items: tanakhBooks,
    }
  },
  computed: {
    field: {
      get() {
        return this.value
      },
      set(val) {
        return this.$emit("input", val)
      },
    },
  },
  methods: {
    handleChange(searchWord) {
      if (
        this.items.filter((value) => {
          return typeof value === "string" ? value.includes(searchWord) : false
        }).length === 0
      ) {
        this.$emit("input", this.field)
      }
    },
  },
}
</script>
