<template>
  <my-dialog v-if="openModal" v-model="openModal" title="עריכת נקודה">
    <div slot="content">
      <v-container>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="3">
              <v-text-field v-model="form.name" :label="$tc('point.name')" :rules="nameValidationRule" />
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="form.locationName" :label="$tc('point.locationName')"
                :rules="nameValidationRule" />
            </v-col>
            <v-col cols="3">
              <v-select required v-model="form.type" :items="types" item-text="text" item-value="value"
                :label="$tc('point.type')" :rules="selectValidationRule" />
            </v-col>
            <v-col>
              <v-select required multiple v-model="form.areas" item-text="title" item-value="_id" :items="areas"
                :label="$tc('point.area')" />
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="form.location.coordinates[0]" :rules="requiredValidationCoordinates" type="number"
                required :label="$tc('point.longitude')" />
            </v-col>

            <v-col cols="3">
              <v-text-field v-model="form.location.coordinates[1]" :rules="requiredValidationCoordinates" type="number"
                required :label="$tc('point.latitude')" />
            </v-col>
            <v-col>
              <v-checkbox v-model="form.inDispute" :label="$t('point.inDispute')" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10">
              <v-textarea required :rules="rules" counter v-model="form.info" rows="1" row-height="15"
                :label="$tc('point.info')" auto-grow />
            </v-col>
            <v-col>
              <v-checkbox v-model="form.showInfo" :label="$t('point.showInfo')" />
            </v-col>
            <v-col cols="10">
              <v-textarea v-model="form.description" rows="1" row-height="15" :label="$tc('point.description')"
                auto-grow />
            </v-col>
          </v-row>
          <item-row-edit v-model="form.verses" v-if="form.verses.length" name="verse" />
          <item-row-edit v-model="form.videos" v-if="form.videos.length" name="video" />
          <item-row-edit v-model="form.audios" v-if="form.audios.length" name="audio" />
          <item-row-edit v-model="form.medias" v-if="form.medias.length" name="media" />
          <item-row-edit v-model="form.games" v-if="form.games.length" name="game" />
        </v-form>
      </v-container>
    </div>
    <div slot="actions">
      <v-btn @click="addLinks('verses')" class="mx-2">
        {{ $t("point.addVerses") }}
        <v-icon right dark>mdi-book-open-variant</v-icon>
      </v-btn>
      <v-btn @click="addLinks('videos')" class="mx-2">
        {{ $t("point.addVideo") }}
        <v-icon right dark>mdi-movie-open-play-outline</v-icon>
      </v-btn>
      <v-btn @click="addLinks('audios')" class="mx-2">
        {{ $t("point.addAudio") }}
        <v-icon right dark>mdi-headphones-box</v-icon>
      </v-btn>
      <v-btn @click="addLinks('medias')" class="mx-2">
        {{ $t("point.addMedia") }}
        <v-icon right dark>mdi-image</v-icon>
      </v-btn>
      <v-btn @click="addLinks('games')" class="mx-2">
        {{ $t("point.addGame") }}
        <v-icon right dark>mdi-controller</v-icon>
      </v-btn>
      <div v-if="err" class="text-center error--text pt-5">
        <span>{{ err }}</span>
      </div>
      <div class="text-center pt-5">
        <v-btn class="green white--text" @click="submit" v-text="isNew ? $t('generics.save') : $t('generics.save')" />
      </div>
    </div>
  </my-dialog>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue"
import pointsTypes from "@/templates/pointsTypes"
import ItemRowEdit from "../ItemRowEdit.vue"
import validationMixins from "@/mixins/validation"
export default {
  name: "MarkerHandler",
  props: {
    value: Boolean,
    marker: Object,
    isNew: { type: Boolean, default: true },
  },
  mixins: [validationMixins],
  components: { MyDialog, ItemRowEdit },
  data() {
    return {
      rules: [v => v.length <= 200 || 'מקסימום 200 תווים'],
      err: "",
      types: pointsTypes,
      linkTemplate: {
        title: "",
        link: "",
        isLink: true,
      },
      form: {
        name: "",
        locationName: "",
        type: "",
        inDispute: false,
        areas: [],
        info: "",
        description: "",
        showInfo: true,
        verses: [],
        audios: [],
        videos: [],
        medias: [],
        games: [],
        location: {
          type: "Point",
          coordinates: [null, null],
        },
      },
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    areas() {
      return this.$store.getters.areas
    },
  },
  methods: {
    addLinks(name) {
      if (!this.objectInArrayValidate(this.form[name])) return
      let template = {}
      switch (name) {
        case "verses":
          template = {
            source: "",
            text: "",
          }
          break
        default:
          template = this.linkTemplate
          break
      }
      this.form[name].push({ ...template })
    },
    async submit() {
      if (!this.validateForm()) return this.createErr("לא כל השדות מלאים")
      const action = this.isNew ? "store" : "update"
      try {
        await this.$store.dispatch("point/" + action, this.form)
        this.openModal = false
      } catch (e) {
        console.log(e)
      }
    },
    validateForm() {
      return this.$refs.form.validate()
    },
    createErr(msg) {
      this.err = msg
      setTimeout(() => {
        this.err = null
      }, 2000)
      return
    },
    async create() { },
    async save() { },
    async destroy() { },

    objectInArrayValidate(arr) {
      return arr.every((o) => {
        return Object.values(o).every((x) => x !== null && x !== "")
      })
    },
    numValidate: (n) => /^\d*$/.test(n),
    coordsValidate: (n) => /^-?[\d]{1,3}[.][\d]+$/.test(n),
  },
  beforeMount() {
    if (!this.isNew) {
      this.form = { ...this.form, ...this.marker }
    } else {
      this.form.location.coordinates[0] = this.marker?.lat
      this.form.location.coordinates[1] = this.marker?.lng
    }
  },
}
</script>
