<template>
	<v-container>
		<h3>מפת מנהל</h3>
		<admin-map />
	</v-container>
</template>

<script>
import AdminMap from "@/components/AdminMap.vue"
export default {
	name: "DashboardPage",
	components: { AdminMap },
	data() {
		return {}
	}
}
</script>
