<template>
  <div>
    <points-table />
  </div>
</template>
<script>
import PointsTable from "@/components/tables/Points.vue"
export default {
  name: "points-page",
  components: { PointsTable },
  data: () => ({}),
  computed: {},
  methods: {},
}
</script>
