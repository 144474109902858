<template>
  <div>
    <div ref="gMap" id="google_map" />
    <marker-handler
      v-model="addMarkerDialog"
      v-if="addMarkerDialog"
      :marker="currentMarker"
      :isNew="true"
    />
    <marker-handler
      v-model="editMarkerDialog"
      v-if="editMarkerDialog"
      :isNew="false"
      :marker="currentMarker"
    />
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import MarkerHandler from "@/components/handlers/MarkerHandler.vue"
export default {
  Name: "AdminMap",
  components: { MarkerHandler },
  data() {
    return {
      addMarkerDialog: false,
      editMarkerDialog: false,
      map: null,
      google: null,
      markersObjects: [],
      currentMarker: {},
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    points() {
      return this.$store.getters.points
    },
    polygon() {
      return this.$store.getters.polygon || []
    },
    areas() {
      return this.$store.getters.areas
    },
  },
  watch: {
    points(arr) {
      this.removeMarkers()
      this.markersAll(arr)
    },
  },
  methods: {
    makeLabelInMap(point, position) {
      new window.MapLabel({
        text: point.title,
        position: point?.label?.position || position,
        map: this.map,
        fontSize: point?.label?.fontSize || 25,
        strokeWeight: point?.label?.strokeWeight || 4,
        strokeColor: point?.label?.strokeColor || "#000",
        align: point?.label?.align || "center",
        fontColor: point?.label?.fontColor || "#FFFFFF",
      })
    },
    createPolygons(arr) {
      arr?.forEach((polygon) => {
        let bounds = new this.google.maps.LatLngBounds()
        const paths = polygon.location?.coordinates[0].map((p) => {
          const latLng = new this.google.maps.LatLng(p[1], p[0])
          bounds.extend(latLng)
          return latLng
        })
        this.makeLabelInMap(polygon, bounds.getCenter())
        const myPolygon = new this.google.maps.Polygon({
          paths,
          clickable: false,
          strokeColor: polygon.color,
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: polygon.color,
          fillOpacity: 0.35,
        })
        myPolygon.setMap(this.map)
      })
    },
    markersAll(arr) {
      arr.forEach((item) => {
        this.markersObjects.push(this.appendMarker(item))
      })
    },
    openAddMarkerDialog({ lat = 0.0, lng = 0.0 }) {
      this.currentMarker = { lat, lng }
      this.addMarkerDialog = true
    },
    appendMarker({ _id, icon, type, name, location: { coordinates } }) {
      if (!this.google) return
      let marker = new this.google.maps.Marker({
        position: {
          lat: parseFloat(coordinates[0]),
          lng: parseFloat(coordinates[1]),
        },
        // animation:this.google.maps.Animation.DROP,
        icon: icon ? icon : process.env.VUE_APP_SERVER+"/assets/markerGreen.png",
        label: {
          text: `${name}`,
          fontSize: "12px",
          fontWeight: "normal",
          className: "label-marker",
        },
        type: type === "myLocation" ? "myLocation" : "",
        _id: _id,
        map: this.map,
      })
      marker.addListener("click", this.editMarker)
      return marker
    },
    editMarker(e) {
      this.findMarkerByCoords(e.latLng.lat(), e.latLng.lng())
      this.editMarkerDialog = true
    },
    findMarkerByCoords(lat, lng) {
      this.isNew = false
      this.currentMarker = this.points.find((m) => {
        const [thisLat, thisLng] = m.location.coordinates
        return +thisLat === +lat && +thisLng === +lng
      })
    },
    removeMarkers() {
      this.markersObjects.forEach((m) => m?.setMap(null))
    },
    handleMapClick(e) {
      this.openAddMarkerDialog({ lat: e.latLng.lat(), lng: e.latLng.lng() })
    },
    async loadMap() {
      const loaderOptions = {
        apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        append: false,
        version: "weekly",
        language: this.$i18n.locale === "he" ? "iw" : "en",
      }
      const mapOptions = {
        center: {
          lat: 31.771959,
          lng: 35.217018,
        },
        fullscreenControl: false,
        zoom: 8,
      }
      const loader = new Loader(loaderOptions)
      await loader.load()
      this.google = await window.google
      this.map = new this.google.maps.Map(this.$refs.gMap, mapOptions)
      this.google.maps.event.addListener(this.map, "click", this.handleMapClick)
      if (this.polygon.length) {
        this.createPolygons(this.polygon)
      }
    },
    async loadMapLabel() {
      const localThis = this

      const MapLabel = document.createElement("script")
      MapLabel.type = "text/javascript"
      MapLabel.src =
        "https://cdn.rawgit.com/googlemaps/js-map-label/gh-pages/src/maplabel.js"

      MapLabel.onload = MapLabel.onreadystatechange = function () {
        if (!this.readyState || this.readyState == "complete") {
          localThis.afterLoad()
        }
      }
      document.head.appendChild(MapLabel)
    },
    async afterLoad() {
      if (this.areas.length) {
        this.createPolygons(this.areas)
      }
    },
  },
  async mounted() {
    await this.loadMap()
    await this.markersAll(this.points)
    await this.loadMapLabel()
  },
}
</script>
<style lang="scss" scoped>
#google_map {
  width: 85vw;
  height: 85vh;
}
::v-deep .label-marker {
  top: 0;
  right: -50px;
  padding: 5px;
  background-color: aliceblue;
  border-radius: 20%;
  border: solid 1px var(--gold);
  margin: 0 0 100px 0;
}
</style>
