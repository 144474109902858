<template>
  <v-navigation-drawer app right v-model="openModal">
    <div class="d-flex justify-center">
      <h3
        class="primary--text font-weight-bold mt-3"
        v-text="$t('generics.mainMenu')"
      />
    </div>
    <v-divider class="my-2" />
    <v-list nav dense v-for="(item, i) in items" :key="i">
      <v-list-item
        @click="goRoute(item)"
        :disabled="$route.name === item.route"
        :class="$route.name === item.route ? 'red white--text' : 'white'"
      >
        <v-list-item-icon>
          <v-icon :color="$route.name === item.route ? 'white' : 'grey'">{{
            item.icon
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="item.title" />
      </v-list-item>
    </v-list>
    <v-divider class="mt-10" />
    <v-list-item @click="logout">
      <v-list-item-icon>
        <v-icon color="red">mdi-logout</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="red--text" v-text="$t('auth.logout')" />
    </v-list-item>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "MyDrawer",
  props: { value: Boolean },
  data() {
    return {
      excelDialog: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    items() {
      //   if (!this.isAdmin) return []
      //   const buttonsData = [
      //     {
      //       title: "מסך אירועים",
      //       route: "/dashboard-admin",
      //     },
      //     {
      //       title: "ניהול אירוע בזמן אמת",
      //       route: "/event-admin",
      //     },
      //     {
      //       title: "דוחו”ת",
      //       route: "/todo/logistics",
      //     },
      //   ]
      //   // const appRoutes = this.$router.getRoutes();
      //   // filter unauthorized routes
      //   return buttonsData.filter((/*{ route: routeName }*/) => {
      //     // const route = appRoutes.find(({ name }) => name === routeName);
      //     // const routeMaxRole = route?.meta?.maxRole;
      //     // const userRole = this.$store.getters?.role;
      //     // return userRole <= routeMaxRole;
      //     return true
      //   })
      // },
      let buttonsData = [
        {
          title: "בית",
          icon: "mdi-home",
          route: "Dashboard",
        },
        {
          title: "עריכת נחלות",
          icon: "mdi-map-legend",
          route: "Areas",
          admin: true,
        },
        {
          title: "נקודות",
          icon: "mdi-map-marker",
          route: "Points",
        },
        {
          title: "משתמשים",
          icon: "mdi-account-group",
          route: "Users",
        },
        {
          title: "פניות",
          icon: "mdi-message-text",
          route: "Messages",
        },
        // {
        //   title: "תגיות",
        //   icon: "mdi-tag-multiple",
        //   route: "Tags",
        // },
        {
          title: "אודות",
          icon: "mdi-tag-multiple",
          route: "About",
        },
        {
          title: "סטטיסטיקות",
          icon: "mdi-chart-line",
          // route: "Statistics",
          route: "",
          link: "http://www.example.com/",
        },
      ]
      buttonsData = buttonsData.filter((r) => {
        if (this.isAdmin) return true
        return !r.admin
      })
      return buttonsData
    },
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
  methods: {
    goRoute(r) {
      if (!r.route) {
        window.open(r.link, "_blank")
      }
      this.$router.push({ name: r.route })
      this.openModal = false
    },
    async logout() {
      await this.$store.dispatch("auth/logout", {
        toastOptions: { disabled: true },
      })
    },
  },
}
</script>
