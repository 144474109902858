<template>
  <div>
    <div class="map-card">
      <section class="map-container">
        <div id="map-id"></div>
      </section>
    </div>
    <area-handler v-if="openModel" v-model="openModel" :area="current" />
  </div>
</template>

<script>
// https://github.com/geoman-io/leaflet-geoman
// https://codesandbox.io/s/nzw9n?file=/src/App.vue:237-243
import L from "leaflet"
import "@geoman-io/leaflet-geoman-free"
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css"
import "leaflet/dist/leaflet.css"

import AreaHandler from "./handlers/AreaHandler.vue"
export default {
  name: "EditPolygon",
  components: { AreaHandler },
  data() {
    return {
      map: null,
      drawnItems: null,
      areasInMap: [],
      current: {},
      openModel: false,
    }
  },
  computed: {
    areas() {
      return this.$store.getters.areas
    },
  },
  watch: {
    areas(arr) {
      this.removePolygon()
      this.createAllPolygon(arr)
    },
  },
  methods: {
    createAllPolygon(areas) {
      areas.forEach((area) => {
        this.addPolygon(area)
      })
    },
    addPolygon(polygon) {
      const formatedPolygon = polygon?.location?.coordinates[0]?.map(
        (point) => [point[1], point[0]]
      )
      const newPolygon = L.polygon(formatedPolygon, {
        color: polygon.color ?? "red",
        fillColor: polygon.color ?? "#f0f3",
        fillOpacity: 0.5,
        areaInBase: true,
        _id: polygon._id,
      })
      this.addPolygonTooltip(newPolygon, polygon.title)
      newPolygon.on("click", () => this.editArea({ ...polygon, ...newPolygon }))
      this.areasInMap.push(newPolygon)
      this.drawnItems.addLayer(newPolygon)
    },
    addPolygonTooltip(polygon, title) {
      const center = polygon.getBounds().getCenter()
      polygon
        .bindTooltip(title, {
          offset: L.point(center.lat, center.lng),
          permanent: true,
          direction: "center",
          sticky: true,
        })
        .openTooltip()
    },
    editArea(item) {
      this.current = item
      this.openModel = true
    },
    removePolygon() {
      this.areasInMap.forEach((area) => area.remove())
    },
    functionOnMap() {
      this.map.on("pm:globaleditmodetoggled", (e) => {
        if (e.enabled) return
        const newAreasToUpdate = Object.values(e.map._targets)
          .filter((target) => target.options.areaInBase)
          .map((area) => ({
            fieldToUpdate: "location.coordinates",
            _id: area.options._id,
            "location.coordinates": [
              area._latlngs[0].map((latLng) => [latLng.lng, latLng.lat]),
            ],
          }))
        this.$store.dispatch("area/updateAll", newAreasToUpdate)
      })
    },
    async initMap() {
      this.map = L.map("map-id", {
        center: [31.7802637, 34.993128],
        zoom: 8,
      })
      this.map.pm.addControls({
        position: "topleft",
        drawCircle: false,
        drawCircleMarker: false,
        drawText: false,
        drawPolygon: false,
        drawPolyline: false,
        drawMarker: false,
        drawRectangle: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        rotateMode: false,
      })
      L.tileLayer(
        "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        // openstreetmap map
        // "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
          minZoom: 1,
          // FOR openstreetmap map credit
          // attribution:
          //   '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          id: "mapbox.streets",
        }
      ).addTo(this.map)
      this.drawnItems = new L.FeatureGroup()
      this.map.addLayer(this.drawnItems)
      // make polygon not snappable during draw
      // this.map.pm.enableDraw("Polygon", { snappable: false })
      this.functionOnMap()
    },
    async loadData() {
      if (!this.areas.length) {
        await this.$store.dispatch("area/index")
      } else {
        this.createAllPolygon(this.areas)
      }
    },
  },
  async mounted() {
    await this.initMap()
    await this.loadData()
  },
}
</script>

<style>
.map-card {
  height: 90vh;
  width: 100%;
}
.map-container {
  position: sticky;
  height: 100%;
  width: 100%;
}
#map-id {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
</style>
