<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="11">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                :label="$t('generics.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-icon
                color="primary"
                @click="loadData"
                v-text="'mdi-refresh'"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
          :items-per-page="50"
          multi-sort
        >
          <template v-slot:item.target="{ item }">
            {{ $t("message." + item.target) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              @click="checkMessage(item)"
              :disabled="item.isChecked"
              color="green"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn icon @click="showMessage(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn v-if="isAdmin" icon @click="deleteItem(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <message-handler
      v-if="editDialog"
      v-model="editDialog"
      :message="chosen"
      :isNew="false"
    />
  </div>
</template>

<script>
import MessageHandler from "@/components/handlers/MessageHandler.vue"
export default {
  name: "MessagesTable",
  components: { MessageHandler },
  data() {
    return {
      loading: false,
      headers: [
        { text: this.$tc("generics.fullName"), value: "fullName" },
        { text: this.$tc("userManagement.email"), value: "email" },
        { text: this.$tc("message.target"), value: "target" },
        {
          text: this.$t("generics.actions"),
          value: "actions",
          width: 55 * 3,
        },
      ],
      chosen: null,
      search: "",
      editDialog: false,
    }
  },
  computed: {
    items() {
      return this.$store.getters.messages
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
  methods: {
    async loadData() {
      this.loading = true
      await this.$store.dispatch("message/index")
      this.loading = false
    },
    showMessage(item) {
      this.chosen = item
      this.editDialog = true
    },
    async checkMessage(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "question",
        title: `?${this.$t("message.check")}`,
      })
      if (isConfirmed) {
        await this.$store.dispatch("message/check", {
          ...item,
          isChecked: !item.isChecked,
        })
      }
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "warning",
        title: `?${this.$t("generics.deleteThe")} ${item.name}`,
      })
      if (isConfirmed) {
        await this.$store.dispatch("message/destroy", item.id)
      }
    },
  },
  async mounted() {
    if (!this.items.length) {
      await this.loadData()
    }
  },
}
</script>
