<template>
  <v-row>
    <v-col cols="12" class="ma-0 pa-0 mr-1"> {{ title }}: </v-col>
    <v-col class="wrapper">
      <div
        v-for="(c, i) in colors"
        :key="c"
        :class="`${c === color ? 'focused' : 'circle-button '}`"
        class="mouse-pointer ma-1"
        @click="pick(i)"
        :style="`background-color: ${c}`"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "color-platen",
  props: { value: String, title: String },
  computed: {
    color: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
  data() {
    return {
      idx: 0,
      colors: [
        "red",
        "cyan",
        "blue",
        "darkBlue",
        "lightblue",
        "purple",
        "yellow",
        "lime",
        "magenta",
        "pink",
        "brown",
        "orange",
      ],
    }
  },
  methods: {
    pick(i) {
      this.color = this.colors[i]
    },
  },
}
</script>
<style scoped lang="scss">
.wrapper {
  width: 300px;
  display: flex;
  flex-wrap: wrap;

  .circle-button {
    width: 14px;
    height: 14px;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin-left: 3px;
  }

  .focused {
    border: 2px solid white;
    outline: 2px solid black;
    padding: 8px;
    border-radius: 50%;
    margin: 0 3px;
  }
}
</style>
