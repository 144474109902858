<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="11">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                :label="$t('generics.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1"  class="d-flex align-center justify-center">
              <v-icon
                color="primary"
                @click="loadData"
                v-text="'mdi-refresh'"
              />
              <v-icon
                color="primary"
                @click="addPoint"
                v-text="'mdi-plus'"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
          :items-per-page="50"
          multi-sort
        >
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editItem(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deleteItem(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <marker-handler
      v-if="openDialog"
      v-model="openDialog"
      :marker="chosen"
      :isNew="isNew"
    />
  </div>
</template>

<script>
import MarkerHandler from "@/components/handlers/MarkerHandler.vue"
import pointsTypes from "@/templates/pointsTypes"
export default {
  name: "PointsTable",
  components: { MarkerHandler },
  data() {
    return {
      types: pointsTypes,
      loading: false,
      headers: [
        { text: this.$tc("point.name"), value: "name" },
        { text: this.$tc("point.area",2), value: "areasFormatter" },
        { text: this.$tc("point.type"), value: "typeFormatter" },
        {
          text: this.$t("generics.actions"),
          value: "actions",
          width: 55 * 2,
        },
      ],
      chosen: null,
      search: "",
      openDialog: false,
      isNew:false
    }
  },
  computed: {
    items() {
      const points = this.$store.getters.points.map(point =>{
        point.areasFormatter = this.areasFormatter(point.areas)
        point.typeFormatter = this.typeFormatter(point.type)
        return point
      })
      return points
    },
  },
  methods: {
    areasFormatter(areas){
      let result = areas?.map(area => this.$store.getters.areaById(area)?.title)
      result = result?.join(', ')?.toString()
      return result
    },
    typeFormatter(type){
      let result = this.types?.find(t => t.value===type)
      return result?.text
    },
    async loadData() {
      this.loading = true
      await this.$store.dispatch("point/index")
      this.loading = false
    },
    editItem(item) {
      this.chosen = item
      this.isNew = false
      this.openDialog = true
    },
    addPoint() {
      this.chosen = null
      this.isNew = true
      this.openDialog = true
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "warning",
        title: `?${this.$t("generics.deleteThe")} ${item.name}`,
      })
      if (isConfirmed) {
        await this.$store.dispatch("point/destroy", item._id)
      }
    },
  },
  async mounted() {
    if (!this.items.length) {
      await this.loadData()
    }
  },
}
</script>
