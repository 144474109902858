<template>
  <my-dialog v-if="openModal" v-model="openModal" title="פניה">
    <div slot="content">
      <v-container>
        <v-row>
          <v-col>
            <p>
              {{ $t("message.applicantName") }}:
              <v-text-field v-text="message.fullName" class="pt-0 mt-0" />
            </p>
            <span>
              {{ $t("userManagement.email") }}:
              <v-text-field v-text="message.email" class="pt-0 mt-0" />
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span> {{ $t("message.content") }}: </span>
            <v-textarea
              class="pt-0 mt-0"
              v-text="message.content"
              rows="1"
              row-height="15"
              :label="$tc('point.info')"
              auto-grow
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div slot="actions"></div>
  </my-dialog>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue"
export default {
  name: "MarkerHandler",
  props: {
    value: Boolean,
    message: Object,
    isNew: { type: Boolean, default: true },
  },
  components: { MyDialog },
  data() {
    return {}
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
  methods: {
    submit() {
      const action = this.isNew ? "store" : "update"
      this.$store.dispatch("point/" + action, this.form)
      this.openModal = false
    },
    async create() {},
    async save() {},
    async destroy() {},
    numValidate: (n) => /^\d*$/.test(n),
    coordsValidate: (n) => /^-?[\d]{1,3}[.][\d]+$/.test(n),
  },
  mounted() {},
}
</script>
