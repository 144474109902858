import myAxios from "@/services/myAxios"
export default {
  state: {
    message: null, //chosen message
    messages: [],
    filtered: [],
  },
  getters: {
    messages: (state) => state.messages,
    message: (state) => state.message,
    messageById: (state) => (id) => state.messages.find(p=>p._id===id),
    messageFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all messages
    "messages/set": (state, payload) => {
      state.messages = payload
      state.filtered = [...state.messages]
    },
    //sets chosen messages
    "messages/chosen": (state, ids) => {
      state.filtered =
        !ids?.length < 0
          ? []
          : state.messages.filter((e) => ids.indexOf(e._id) !== -1)
    },
    //sets one message
    "message/set": (state, payload) => (state.message = payload),
    //sets one message
    "message/findByIdAndSet": (state, id) => {
      const message = id ? state.messages.find(e => e._id === id) : null
      state.message = message ? message : null
    },
    //filters the message's array by message's key and message's val
    "messages/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.messages]
        : state.messages.filter((f) => f[key] === val)
    },
    //store one message
    "message/store": (state, payload) => state.messages.push(payload),
    //destroys one message
    "message/destroy": (state, id) =>
      (state.messages = state.messages.filter((item) => {
        return item._id !== id
      })),
    //updates one message
    "message/update": (state, payload) => {
      state.messages = state.messages.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload }
        }
        return item
      })
    },
  },
  actions: {
    //fetch all messages
    "message/index": async (context) => {
      const { data } = await myAxios.get("/message")
      context.commit("messages/set", data)
    },
    //fetch one message by id
    "message/show": async (context, id) => {
      let { data } = await myAxios.get("/message/" + id)
      context.commit("message/set", data)
    },
    //stores one message
    "message/store": async (context, payload) => {
      let { data } = await myAxios.post("/message", { ...payload })
      context.commit("message/store", data)
    },
    //destroys one message
    "message/destroy": async (context, id) => {
      await myAxios.delete("/message/" + id)
      context.commit("message/destroy", id)
    },
    //checked one message
    "message/check": async (context, payload) => {
      await myAxios.put("/message/check/" +  payload._id, payload)
      context.commit("message/update", payload)
    },
    //updates one message by its id
    "message/update": async (context, payload) => {
      await myAxios.put("/message/" + payload._id, payload)
      context.commit("message/update", payload)
    },
  },
}
