export const tanakhBooks = [
  { header: 'חומש בראשית' },
  { divider: true },
  "בראשית",
  "נח",
  "לך לך",
  "וירא",
  "חיי שרה",
  "תולדות",
  "ויצא",
  "וישלח",
  "וישב",
  "מקץ",
  "ויגש",
  "ויחי",
  { divider: true },
  { header: 'חומש שמות' },
  { divider: true },
  "שמות",
  "וארא",
  "בא",
  "בשלח",
  "יתרו",
  "משפטים",
  "תרומה",
  "תצוה",
  "כי תשא",
  "ויקהל",
  "פקודי",
  { divider: true },
  { header: 'חומש ויקרא' },
  { divider: true },
  "ויקרא",
  "צו",
  "שמיני",
  "תזריע",
  "מצורע",
  "אחרי מות",
  "קדושים",
  "אמור",
  "בהר",
  "בחוקותי",
  { divider: true },
  { header: 'חומש במדבר' },
  { divider: true },
  "במדבר",
  "נשא",
  "בהעלותך",
  "שלח לך",
  "קרח",
  "חקת",
  "בלק",
  "פינחס",
  "מטות",
  "מסעי",
  { divider: true },
  { header: 'חומש דברים' },
  { divider: true },
  "דברים",
  "ואתחנן",
  "עקב",
  "ראה",
  "שופטים (חומש)",
  "כי תצא",
  "כי תבוא",
  "נצבים",
  "וילך",
  "האזינו",
  "וזאת הברכה",
  { divider: true },
  { header: 'נביאים' },
  { divider: true },
  "יהושע",
  "שופטים (נביא)"  ,
  "שמואל א",
  "שמואל ב",
  "מלכים א",
  "מלכים ב",
  "ישעיהו",
  "ירמיהו",
  "יחזקאל",
  "הושע",
  "יואל",
  "עמוס",
  "עבדיה",
  "יונה",
  "מיכה",
  "נחום",
  "חבקוק",
  "צפניה",
  "חגי",
  "זכריה",
  "מלאכי",
  { divider: true },
  { header: 'כתובים' },
  "תהלים",
  "איוב",
  "דניאל",
  "עזרא",
  "נחמיה",
  "משלי",
  "שיר השירים",
  "רות",
  "איכה",
  "קהלת",
  "אסתר",
  "דברי הימים א",
  "דברי הימים ב",
]
