import Vue from "vue"
import VueRouter from "vue-router"
import store from "./../store/index"
import Login from "../views/Login.vue"
import NotFound from "../views/NotFound.vue"
import Dashboard from "@/views/Dashboard"
import Points from "@/views/Points"
import Users from "@/views/Users"
import Messages from "@/views/Messages"
import Tags from "@/views/Tags"
import About from "@/views/About"
import Statistics from "@/views/Statistics"
import Areas from "@/views/Areas.vue"

Vue.use(VueRouter)

const adminGuard = async (to, from, next) => {
  const isAuth = store.getters.isAuth
  if (!isAuth) return next({ name: "Login" })

  if (store.getters.isAdmin) return next()

  store.commit("clearData")
  return next({ name: "Login" })
}
const authGuard = async (to, from, next) => {
  const isAuth = store.getters.isAuth
  if (!isAuth) return next({ name: "Login" })

  const userRole = store.getters.role
  const routeRoles = [0, 1]
  if (routeRoles.includes(userRole)) return next()
  store.commit("clearData")
  return next({ name: "Login" })
}

const beforeEnterLoginPage = async (to, from, next) => {
  const isAuth = store.getters.isAuth
  return isAuth ? next({ name: "Dashboard" }) : next()
}

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    beforeEnter: beforeEnterLoginPage,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: "/areas",
    name: "Areas",
    component: Areas,
    beforeEnter: adminGuard,
  },
  {
    path: "/points",
    name: "Points",
    component: Points,
    beforeEnter: authGuard,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    beforeEnter: authGuard,
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
    beforeEnter: authGuard,
  },
  {
    path: "/tags",
    name: "Tags",
    component: Tags,
    beforeEnter: authGuard,
  },
  {
    path: "/about",
    name: "About",
    component: About,
    beforeEnter: authGuard,
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    beforeEnter: authGuard,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
