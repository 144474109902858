<template>
  <v-row v-if="name === 'verse'">
    <span class="mt-4 pt-4">{{ $tc(`generics.${name}`, 2) }}:</span>
    <v-col cols="12" v-for="(item, i) in itemList" :key="i">
      <v-row>
        <v-col cols="1" class="d-flex justify-center align-end">
          <v-btn color="red" icon @click="removeRow(itemList[i])">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <span> {{ i + 1 }}. </span>
        </v-col>
        <v-col cols="3">
          <tanakh-autocomplete v-model="itemList[i].source" />
        </v-col>
        <v-col cols="8">
          <v-textarea
            v-model="itemList[i].text"
            rows="1"
            hide-details
            row-height="15"
            :label="$tc(`generics.${name}`)"
            auto-grow
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else>
    <span class="mt-4 pt-4">{{ $tc(`generics.${name}`, 2) }}:</span>
    <v-col cols="12" v-for="(item, i) in itemList" :key="i">
      <v-row>
        <v-col cols="1" class="d-flex justify-center align-end">
          <v-btn color="red" icon @click="removeRow(itemList[i])">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <span> {{ i + 1 }}. </span>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="itemList[i].title"
            :label="$tc(`generics.title`)"
            hide-details
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="itemList[i].link"
            rows="1"
            hide-details
            row-height="15"
            :label="$tc(`generics.link`)"
            auto-grow
          />
        </v-col>
        <v-col cols="2">
          <v-switch
            v-model="itemList[i].isLink"
            :label="
              itemList[i].isLink
                ? $tc(`generics.link`)
                : $tc(`generics.assimilation`)
            "
            color="primary"
            hide-details
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import TanakhAutocomplete from "./tanakhAutocomplete.vue"
export default {
  name: "items-row-edit",
  props: { value: Array, name: String, type: String },
  computed: {
    itemList: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
  methods: {
    removeRow(item) {
      const index = this.itemList.indexOf(item)
      if (index !== -1) {
        this.itemList.splice(index, 1)
      }
    },
  },
  components: { TanakhAutocomplete },
}
</script>
