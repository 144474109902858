<template>
  <div class="d-flex justify-center align-center">
    <!-- TODO: make this url to rely url-->
    <a href="http://www.example.com" target="_blank">Example</a>
  </div>
</template>
<script>
export default {
  name: "statistics-page",
  data: () => ({}),
  computed: {},
  methods: {},
}
</script>
