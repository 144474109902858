<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="11">
              <v-row>
                <v-col cols="6" v-if="isAdmin">
                  <v-select
                    class="ml-1"
                    :items="userRoleItems"
                    :label="$t('userManagement.role')"
                    v-model="roleQuery"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    icon
                    :label="$t('generics.search')"
                    single-line
                    hide-details
                  ></v-text-field>
                  <!-- </v-row> -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1">
              <v-icon
                color="primary"
                @click="addUser"
                v-text="'mdi-plus'"
              />
              <v-icon
                color="primary"
                @click="loadData"
                v-text="'mdi-refresh'"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="computedHeaders"
          :items="items"
          :search="search"
          :loading="loading"
          :items-per-page="20"
        >
          <template v-slot:item.role="{ item }">{{
            getRoleLabel(item)
          }}</template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <v-icon @click="editUser(item)">mdi-pencil</v-icon>
              <v-icon v-if="isAdmin" @click="deleteItem(item)"
                >mdi-delete</v-icon
              >
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <user-handler
      v-if="openDialog"
      v-model="openDialog"
      :isNew="isNew"
      :selectedUser="selectedUser"
    />
  </div>
</template>

<script>
import UserHandler from "@/components/handlers/UserHandler"
import { userRoles } from "@/data/lists"

export default {
  name: "user-table",
  components: { UserHandler },
  data() {
    return {
      search: "",
      roleQuery: "all",
      loading: false,
      addDialog: false,
      openDialog: false,
      selectedUser: null,
      isNew:null
    }
  },
  computed: {
    computedHeaders() {
      const headers = [
        {
          text: this.$t("userDetails.firstName"),
          value: `name.firstName`,
        },
        {
          text: this.$t("userDetails.lastName"),
          value: `name.lastName`,
        },
        { text: this.$t("userManagement.email"), value: "email" },
        { text: this.$t("userDetails.phone"), value: "phone" },
        { text: this.$t("userDetails.city"), value: "address.city" },
        { text: this.$t("userDetails.street"), value: "address.street" },
        { text: this.$t("userDetails.number"), value: "address.number" },
        { text: this.$t("userDetails.profession"), value: "profession" },
        {
          text: this.$t("userManagement.role"),
          value: "role",
          filter: (value) => {
            if (this.isAdmin) {
              if (this.roleQuery === "" || this.roleQuery === "all") return true
              return value === this.roleQuery
            } else {
              console.log(this.roleQuery)
              if (this.roleQuery === "" || this.roleQuery === "all") return true
              return value === this.roleQuery
            }
          },
        },
      ]

      headers.push({
        text: `${this.$t("generics.actions")}`,
        value: "actions",
        width: "10%",
      })

      return headers
    },

    items() {
      return this.$store.getters.users
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    userRoleItems() {
      return [
        {
          text: this.$t("userManagement.allUsers"),
          value: "all",
        },
        { text: this.$t("userManagement.roleTypes.editer"), value: 1 },
        {
          text: this.$t("userManagement.roleTypes.admin"),
          value: 0,
        },
      ]
    },
  },
  methods: {
    async loadData() {
      this.loading = true
      await this.$store.dispatch("users/index")
      this.loading = false
    },
    addUser() {
      this.isNew = true
      this.selectedUser = null
      this.openDialog = true
    },
    editUser(item) {
      this.isNew = false
      this.selectedUser = item
      this.openDialog = true
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        icon: "warning",
        title: `${this.$t("generics.delete")} ${item.name.firstName} ${
          item.name.lastName
        }?`,
      })
      if (isConfirmed) {
        await this.$store.dispatch("user/destroy", item._id)
      }
    },
    getRoleLabel(item) {
      return userRoles(this).find(({ value }) => value === item.role).text
    },
  },
  async mounted() {
    if (!this.items.length) {
      await this.loadData()
    }
    if (!this.isAdmin) {
      this.roleQuery = 1
    }
  },
}
</script>

<style></style>
