<template>
  <v-container>
    <edit-polygon />
  </v-container>
</template>

<script>
import EditPolygon from "@/components/EditPolygon.vue"
export default {
  name: "AreasPage",
  components: { EditPolygon },
}
</script>
