import generics from "./generics/he"
const he = {
  ...generics,
  generics: {
    ...generics.generics,
    verse: "פסוק | פסוקים",
    link: "קישור | קישורים",
    media: "תמונה | תמונות",
    game: "משחק | משחקים",
    video: "סרטון | סרטונים",
    audio: "פודקסט | פודקסטים",
    assimilation: "הַטמָעָה | הַטמָעָות",
  },
  point: {
    name: "שם הנקודה | שם הנקודות",
    locationName: "שם המקום",
    type: "אופי הנקודה",
    info: "תקציר",
    showInfo: "הצג תקציר",
    area: "נחלה | נחלות",
    addVerses: "הוסף פסוק",
    addVideo: "הוסף וידיאו",
    addAudio: "הוסף פודקסט",
    addMedia: "הוסף תמונה",
    addGame: "הוסף משחק",
    inDispute: "שנוי במחלוקת",
    selectTanakhBooks: "בחר מקור מהתנך",
    longitude: "קו אורך",
    latitude: "קו רוחב",
    description: "תיאור",
  },
  area: {
    color: "צבע הנחלה",
    title: "שם הנחלה",
    edit: "עריכת נחלה",
  },
  message: {
    applicantName: "שם הפונה",
    content: "תוכן הפניה",
    target: "סוג הפניה",
    contact: "יצירת קשר",
    knowledge: "תרומת ידע",
    check: "סמן כטופל",
  },
  about: {
    select: "בחר איזה 'אודות' אתה רוצה",
  },
  userManagement: {
    name: "שם",
    email: "אימייל",
    phone: "טלפון",
    password: "סיסמה",
    role: "סוג משתמש",
    changePassword: "שנה סיסמה",
    cancelPasswordChange: "בטל שינוי סיסמה",
    new: "הוספת משתמש",
    edit: "עריכת משתמש",
    createUserSuccessMessage: "המשתמש נוסף בהצלחה",
    editUserSuccessMessage: "המשתמש עודכן בהצלחה",
    changeUserPasswordWarning: "הסיסמא של {name} עומדת להשתנות. אתה בטוח?",
    allUsers: "כל המשתמשים",
    roleTypes: {
      editer: "עורך",
      admin: "מנהל ראשי",
      regularUser: "משתמש רגיל",
    },
  },
  userDetails: {
    userDetails: "פרטים אישיים",
    name: "שם",
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    phone: "טלפון",
    email: "אימייל",
    address: "כתובת",
    street: "רחוב",
    number: "מספר בית",
    city: "עיר",
    profession: "מקצוע",
  },
  route: {
    Dashboard: "מפת מנהל",
    Points: "נקודות",
    Areas: "עריכת נחלות",
    Users: "משתמשים",
    Messages: "פניות",
    Tags: "תגיות",
    About: "אודות",
    Statistics: "סטטיסטיקות",
  },
}
export default he
