<template>
  <my-dialog v-if="openModal" v-model="openModal" :title="$tc('area.edit')">
    <div slot="content">
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="form.title" :label="$tc('area.title')" />
          </v-col>
          <v-col cols="6">
            <color-platen v-model="form.color" :title="$tc('area.color')" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div slot="actions">
      <div class="text-center">
        <v-btn
          class="green white--text"
          @click="submit"
          v-text="$t('generics.save')"
        />
      </div>
    </div>
  </my-dialog>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue"
import ColorPlaten from "../ColorPlaten.vue"
export default {
  name: "AreaHandler",
  props: {
    value: Boolean,
    area: { type: Object, default: () => ({}) },
  },
  components: { MyDialog, ColorPlaten },
  data() {
    return {
      form: {
        title: "",
        color: "",
      },
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
  methods: {
    submit() {
      this.$store.dispatch("area/update", this.form)
      this.openModal = false
    },
  },
  mounted() {
    this.form.color = this.area?.color
    this.form.title = this.area?.title
    this.form._id = this.area?._id
  },
}
</script>
