<template>
  <div>
    <my-dialog
      :title="
        isNew ? $t('userManagement.edit') : $t('userManagement.new')
      "
      v-model="openModal"
    >
      <template v-slot:content>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('userDetails.firstName')"
                v-model="form.name.firstName"
                autofocus
                :rules="nameValidationRule"
                @keypress.enter="submit"
              />
              <v-text-field
                :label="$t('userDetails.lastName')"
                v-model="form.name.lastName"
                :rules="nameValidationRule"
                @keypress.enter="submit"
              />
              <v-text-field
                :label="$t('userManagement.email')"
                v-model="form.email"
                :rules="emailValidationRule"
                @keypress.enter="submit"
              />
              <v-text-field
                :label="$t('userManagement.phone')"
                v-model="form.phone"
                @keypress.enter="submit"
              />
              <v-text-field
                :label="$t('userDetails.city')"
                v-model="form.address.city"
                @keypress.enter="submit"
              />
              <v-text-field
                :label="$t('userDetails.street')"
                v-model="form.address.street"
                @keypress.enter="submit"
              />
              <v-text-field
                :label="$t('userDetails.number')"
                v-model="form.address.number"
                @keypress.enter="submit"
              />
              <v-text-field
                :label="$t('userDetails.profession')"
                v-model="form.profession"
                @keypress.enter="submit"
              />
              <v-select
                v-if=" isAdmin"
                :items="userRoleItems"
                :label="$t('userManagement.role')"
                :rules="requiredValidationRule"
                v-model="form.role"
              ></v-select>

              <v-text-field
                v-if="isNew || changePasswordMode"
                :label="$t('userManagement.password')"
                v-model="form.password"
                :rules="passwordValidationRule"
                @keypress.enter="submit"
              />
              <span v-if="!isNew"  @click="changePasswordMode = !changePasswordMode">
                {{!changePasswordMode? $t("userManagement.changePassword"):$t("userManagement.cancelPasswordChange") }}
              </span>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <div class="d-flex flex-column align-center">
          <v-alert
            icon="mdi-minus-circle"
            type="error"
            dense
            v-if="errorMessage"
            >{{ errorMessage }}</v-alert
          >
          <v-btn
            text
            color="primary"
            v-text="
              loading
                ? ''
                : !isNew
                ? $t('generics.save')
                : $t('generics.add')
            "
            @click="submit"
            :loading="loading"
          />
        </div>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog"
import { userRoles } from "@/data/lists"
import validationMixins from "@/mixins/validation"
import _ from "lodash"

export default {
  name: "user-handler",
  components: { MyDialog },
  props: {
    value: Boolean,
    selectedUser: {type:Object,default:()=>({})},
    isNew: Boolean,
  },
  mixins: [validationMixins],
  data: () => ({
    form: {
      name: { firstName: "", lastName: "" },
      email: "",
      password: "",
      phone: undefined,
      address: { city: "", street: "", number: "" },
      profession: "",
      role: 1,
    },

    changePasswordMode: null,
    errorMessage: "",
    successMessage: "",
    loading: false,
  }),
  computed: {
    userRoleItems() {
      return userRoles(this)
    },
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
  methods: {
    async submit() {
      this.errorMessage = ""
      this.successMessage = ""
      if (!this.validateForm()) return
      if (!this.changePasswordMode) {
        delete this.form.password
      } 
      this.loading = true
      try {
        if (!this.isNew && this.changePasswordMode) {
          let { isConfirmed } = await this.$createSwal({
            icon: "warning",
            title: this.$t("userManagement.changeUserPasswordWarning", {
              name: this.form.name.firstName +" " +this.form.name.lastName,
            }),
          })
          if (!isConfirmed) return
        }
          
          
        const action = this.isNew ? "user/store" : "user/update"
        await this.$store.dispatch(action, this.form)
        this.openModal = false
      } catch (err) {
        console.error(err.message)
      } finally {
        this.loading = false
      }
    },
    validateForm() {
      return this.$refs.form.validate()
    },
  },
  created() {
    if (!this.isNew) {
      this.form = { ...this.form, ..._.cloneDeep(this.selectedUser) }
    }
  },
}
</script>
