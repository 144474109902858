import myAxios from "@/services/myAxios"
import router from "../../router/index"

export default {
  state: {
    isAuth: false,
    isAdmin: false,
    fullName: null,
    role: null,
    token: null,
  },
  getters: {
    fullName: (state) => state.fullName,
    isAuth: (state) => state.isAuth,
    isAdmin: (state) => state.isAdmin,
    role: (state) => state.role,
    token: (state) => state.token,
  },
  mutations: {
    setName: (state, payload) => (state.fullName = payload),
    "auth/login": (state, user) => {
      state.isAuth = true
      state.fullName = user.fullName
      state.role = user.role
      state.isAdmin = user.role === 0
      state.token = localStorage.getItem("authenticToken")
    },
    "auth/loginByToken"(state, { user, token }) {
      state.token = token
      state.isAuth = true
      state.username = user.username
      state.isAdmin = user.role === 0
      state.role = user.role
      localStorage.setItem("authenticToken", token)
      myAxios.setAuthorization(token)
    },
    clearData: (state) => {
      state.isAuth = false
      state.isAdmin = false
      state.fullName = null
      state.role = null
      state.token = null
      localStorage.setItem("authenticToken", null)
      myAxios.setAuthorization(null)
    },
  },
  actions: {
    "auth/register": async (context, payload) => {
      let { data } = await myAxios.post("/auth/register", payload)
      context.commit("auth/login", data)
    },
    "auth/login": async (context, payload) => {
      let { data } = await myAxios.post("/auth/login", payload)
      context.commit("auth/loginByToken", data)
    },
    "auth/checkLogin": async (context) => {
      let { data } = await myAxios.get("/auth/checkLogin")
      context.commit("auth/login", data)
    },
    "auth/logout": async (context) => {
      await myAxios.post("/auth/logout")
      context.commit("clearData")
      router.push({ name: "Login" })
    },
  },
}
