import myAxios from "@/services/myAxios"
export default {
  state: {
    area: null, //chosen area
    areas: [],
    filtered: [],
  },
  getters: {
    areas: (state) => state.areas,
    area: (state) => state.area,
    areaById: (state) => (id) => state.areas.find((p) => p._id === id),
    areaFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all areas
    "areas/set": (state, payload) => {
      state.areas = payload
      state.filtered = [...state.areas]
    },
    //sets chosen areas
    "areas/chosen": (state, ids) => {
      state.filtered =
        !ids?.length < 0
          ? []
          : state.areas.filter((e) => ids.indexOf(e._id) !== -1)
    },
    //sets one area
    "area/set": (state, payload) => (state.area = payload),
    //sets one area
    "area/findByIdAndSet": (state, id) => {
      const area = id ? state.areas.find((e) => e._id === id) : null
      state.area = area ? area : null
    },
    //filters the area's array by area's key and area's val
    "areas/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.areas]
        : state.areas.filter((f) => f[key] === val)
    },
    //store one area
    "area/store": (state, payload) => state.areas.push(payload),
    //destroys one area
    "area/destroy": (state, id) =>
      (state.areas = state.areas.filter((item) => {
        return item._id !== id
      })),
    //updates one area
    "area/update": (state, payload) => {
      state.areas = state.areas.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload }
        }
        return item
      })
    },
    //updates all areas
    "area/updateAll": (state, payload) => {
      state.areas = state.areas.map((item) => {
        const location = payload.find((a) => item._id === a._id)
        item["location"]["coordinates"] = location["location.coordinates"]
        return item
      })
    },
  },
  actions: {
    //fetch all areas
    "area/index": async (context) => {
      const { data } = await myAxios.get("/area")
      context.commit("areas/set", data)
    },
    //fetch one area by id
    "area/show": async (context, id) => {
      let { data } = await myAxios.get("/area/" + id)
      context.commit("area/set", data)
    },
    //stores one area
    "area/store": async (context, payload) => {
      let { data } = await myAxios.post("/area", { ...payload })
      context.commit("area/store", data)
    },
    //destroys one area
    "area/destroy": async (context, id) => {
      await myAxios.delete("/area/" + id)
      context.commit("area/destroy", id)
    },
    //updates one area by its id
    "area/update": async (context, payload) => {
      await myAxios.put("/area/" + payload._id, payload)
      context.commit("area/update", payload)
    },
    //updates all areas
    "area/updateAll": async (context, payload) => {
      await myAxios.put("/area/all", payload)
      context.commit("area/updateAll", payload)
    },
  },
}
