import myAxios from "@/services/myAxios"
export default {
  state: {
    about: null, //chosen about
    abouts: [],
    filtered: [],
  },
  getters: {
    abouts: (state) => state.abouts,
    about: (state) => state.about,
    aboutById: (state) => (id) => state.abouts.find(p=>p._id===id),
    aboutFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all abouts
    "abouts/set": (state, payload) => {
      state.abouts = payload
      state.filtered = [...state.abouts]
    },
    //sets chosen abouts
    "abouts/chosen": (state, ids) => {
      state.filtered =
        !ids?.length < 0
          ? []
          : state.abouts.filter((e) => ids.indexOf(e._id) !== -1)
    },
    //sets one about
    "about/set": (state, payload) => (state.about = payload),
    //sets one about
    "about/findByIdAndSet": (state, id) => {
      const about = id ? state.abouts.find(e => e._id === id) : null
      state.about = about ? about : null
    },
    //filters the about's array by about's key and about's val
    "abouts/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.abouts]
        : state.abouts.filter((f) => f[key] === val)
    },
    //store one about
    "about/store": (state, payload) => state.abouts.push(payload),
    //destroys one about
    "about/destroy": (state, id) =>
      (state.abouts = state.abouts.filter((item) => {
        return item._id !== id
      })),
    //updates one about
    "about/update": (state, payload) => {
      state.abouts = state.abouts.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload }
        }
        return item
      })
    },
  },
  actions: {
    //fetch all abouts
    "about/index": async (context) => {
      const { data } = await myAxios.get("/about")
      context.commit("abouts/set", data)
    },
    //fetch one about by id
    "about/show": async (context, id) => {
      let { data } = await myAxios.get("/about/" + id)
      context.commit("about/set", data)
    },
    //stores one about
    "about/store": async (context, payload) => {
      let { data } = await myAxios.post("/about", { ...payload })
      context.commit("about/store", data)
    },
    //destroys one about
    "about/destroy": async (context, id) => {
      await myAxios.delete("/about/" + id)
      context.commit("about/destroy", id)
    },
    //checked one about
    "about/check": async (context, payload) => {
      await myAxios.put("/about/check/" +  payload._id, payload)
      context.commit("about/update", payload)
    },
    //updates one about by its id
    "about/update": async (context, payload) => {
      await myAxios.put("/about/" + payload._id, payload)
      context.commit("about/update", payload)
    },
  },
}
