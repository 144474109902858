<template>
<v-container>
  <h1>{{$t('generics.notFound')}}</h1>
</v-container>
</template>

<script>
export default {
  name: "NotFound"
}
</script>
