<template>
  <div>
    <messages-table />
  </div>
</template>
<script>
import MessagesTable from "@/components/tables/Messages.vue"
export default {
  name: "messages-page",
  components: { MessagesTable },
  data: () => ({}),
  computed: {},
  methods: {},
}
</script>
