<template>
  <div>
    <v-container>
      <v-row v-if="!form" class="d-flex justify-center">
        <v-col cols="6">
          <v-select
          :items="abouts"
          v-model="form"
          item-text="title"
          return-object
          icon
          :label="$t('about.select')"
          single-line
          hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-card v-else>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="11">
              <v-select
                :items="abouts"
                v-model="form"
                item-text="title"
                return-object
                icon
                :label="$t('generics.title')"
                single-line
                hide-details
              ></v-select
            ></v-col>
            <v-col cols="1" class="center-all">
              <!-- <v-icon
                class="mr-2"
                :color="disabled ? 'green' : ''"
                @click="disabled = !disabled"
              >
                {{ disabled ? "mdi-pencil" : "mdi-pencil-off" }}
              </v-icon> -->
            </v-col>
            <v-col>
              <v-textarea
                v-model="form.description"
                :label="$t('generics.description')"
                single-line
                hide-details
                :disabled="disabled"
                rows="20"
                row-height="30"
              ></v-textarea
            ></v-col>
            <v-col cols="1" class="d-flex align-end">
              <v-btn
                @click="submit"
                v-text="$t('generics.save')"
                :disabled="disabled"
                v-tex
              ></v-btn
            ></v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "abouts-page",
  data() {
    return {
      loading: false,
      disabled: false,
      form: null,
    }
  },
  computed: {
    abouts() {
      return this.$store.getters.abouts
    },
  },
  methods: {
    async loadData() {
      this.loading = true
      await this.$store.dispatch("about/index")
      this.loading = false
    },
    async submit() {
      await this.$store.dispatch("about/update", this.form)
    },
  },
  async mounted() {
    if (!this.abouts.length) {
      await this.loadData()
    }
  },
}
</script>
